<template>
  <div>
    <v-card class="mb-2">
      <v-card-text>
        documentid={{ document.documentid }}<br/>
        documentitemtypeid={{ document.documenttypeid.documenttypeid }} <br/>
        getDocumentDataStructure= {{ getDocumentDataStructure() }}
      </v-card-text>
    </v-card>
    <v-card v-if="dataStructure">
      <v-card-title>DataStructure</v-card-title>
      <v-card-text class="text-caption"><pre>{{ dataStructure }}</pre></v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import configs from '@/configs'

export default {
  name: 'DocumentSettingPage',
  data: () => ({
    connectionType: configs.connectionProperties.types
  }),
  computed: {
    ...mapState('documents', ['dataStructure', 'document', 'documentTranslations']),
    ...mapGetters('documents', ['getDocumentDataStructure']),
    ...mapMutations('app', ['updateMenu'])
  }
}
</script>